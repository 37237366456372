<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-12-13 17:18:20
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-02-13 10:45:03
 * @FilePath: \dataview-next\src\manage-views\views\login\newLogin\components\Welcome.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="welcome-sider">
    <div class="icon" @click="goHome">
    </div>
    <div class="welcome">
      Welcome!
    </div>
    <div class="welcome-text">
      <span>
        欢迎登录
      </span>
      <img src="../images/logo.png">
      <span>
        飞椽云
      </span>
    </div>
  </section>
</template>
<script>
export default { 
  methods:{
    goHome(){
      window.location.href = 'https://www.bimcc.com/web-page/home'
    }
  }
}
</script>
<style lang="less" scoped>
  .welcome-sider{
    @media (min-width: 1720px) {
      width: 640px;
    }
    @media (max-width: 1720px) {
      flex: 1;
    }
    box-sizing: border-box;
    padding-left: 50px;
    .icon{
      margin-top: 96px;
      width: 350px;
      height: 157px;
      background-image: url(../images/logo-index.png);
      background-size: 100% 100%;
    }
    .welcome{
      padding-left: 10px;
      margin-top: 68px;
      color: #424751;
      font-family: "Source Han Sans CN";
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 114.286% */
    }
    .welcome-text{
      padding-left: 10px;
      margin-top: 16px;
      color: #707786;
      font-family: "PingFang SC";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
      img{
        height: 28px;
        width: auto;
        margin: 0 12px;
      }
    }
  }
</style>
