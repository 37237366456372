<template>
  <el-form
    ref="loginForm"
    class="form-login"
  >
    <div class="code-area">
      <el-image :src="imgUrl" v-loading="codeLoading">
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </el-image>
      <span class="open">打开微信扫一扫</span>
    </div>
    <div class="agreement-tips-for-code">
      <p class="ordinary-text">
        未注册用户扫码后自动登录，注册即代表同意平台
        <span class="active-text" @click.stop="openagreement('privacyagreement')">《隐私协议》</span>
        <span class="active-text" @click.stop="openagreement('userAgreement')">《用户协议》</span>
      </p>
    </div>
    <!-- <div class="switch-versions">筑云飞椽云{{ version }}  <span @click="onchange">切换{{ changeVersion }}</span></div> -->
  </el-form>
</template>

<script>
import { setToken } from '@/utils/tools'
import { userUseCompany } from '@/api/userInfo'
import { get, post } from 'axios'
import { Image } from 'element-ui'
import FuwuAgreement from '@/website-views/views/applyDemo/components/FuwuAgreement.vue'
import UserAgreement from '@/website-views/views/applyDemo/components/UserAgreement.vue'
import { MessageBox } from 'element-ui'
import { mapState } from 'vuex';
export default {
  components: {
    'el-image': Image
  },
  props:{
    loginLoading:{
      type: Boolean,
      default:false
    }
  },
  data(){
    return {
      imgUrl: '',
      code: '',
      codeLoading: false,
      version: 'V1.0'
    }
  },
  computed: {
    ...mapState(['loginVersion']),
  },
  created() {
    this.getCode()
  },
  mounted() {
    this.startPolling()
  },
  beforeDestroy() {
    this.destroyPolling()
  },
  watch: {
    loginVersion: {
      handler() {
        this.getCode()
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    /**
     * @desc: 切换版本
     */
    onchange() {
      if (this.version === 'V2.0') {
        this.version = 'V1.0';
      } else {
        this.version = 'V2.0';
      }
    },
    startPolling() {
      this.timer = setInterval(() => {
        this.getCodeStatus()
      }, 1000)
    },
    destroyPolling() {
      if(this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    getCode() {
      this.codeLoading = true
      let url = ''
      if(this.loginVersion === 'V2.0'){
        url = 'https://y.bimcc.com/api/wechat/qrcode/newSaasLogin'
      }else {
        url = 'https://y.bimcc.com/api/wechat/qrcode/saasLogin'
      }
      get(url).then(res => {
        if(res.data.code === 200) {
          this.imgUrl = res.data.data.url
          this.code = res.data.data.code
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('获取二维码失败！')
      }).finally(() => {
        this.codeLoading = false
      })
    },
    getCodeStatus() {
      post('https://saas-api.bimcc.net/api/saas/wechatCheckCode',
        {
          code: this.code
        }).then(res => {
        if(res.data.code === 200) {
          if(res.data.data !== '1001') {
            this.destroyPolling()
            setToken('')
            sessionStorage.clear()
            localStorage.clear()
            if (res.data.code === 200) {
            // 当老用户没saas企业时 则不让他登录
            if(res.data.data.saas.length === 0 && res.data.data.saas_admin !== 1){
              this.$message.info('登录失败！该用户没有企业系统')
              this.loginLoading = false
              return
            }
            // 系统样式  默认为1 留下后面风格修改
            localStorage.setItem('system_style', 1)
            this.$message.success('登录成功！')
            setToken(res?.data?.data?.token)
            localStorage.setItem('is_company', res?.data?.data?.is_company)
            this.$store.commit('setUserInfo',res.data.data)

            const phoneReg = /^(\d{3})\d{6}(\d{2})$/
            let phone = res.data.data.phone
            if (this.userPhone) {
              this.userPhone = phone.replace(phoneReg, '$1******$2')
            }
            this.userName = res.data.data.name
            this.companyList = res.data.data.saas
            if (res.data.data.saas_admin == 1) {
              localStorage.setItem('company_id', 0) // 当前选择的系统的所有信息
              userUseCompany({ company_id: 0 }).then((r) => {
                localStorage.setItem(
                  'saasTargetArchi',
                  JSON.stringify(
                    r?.data?.data?.department_info
                  )
                )
                this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                localStorage.setItem(
                  'saas',
                  JSON.stringify(
                    res.data.data.saas.length ? res.data.data.saas[0] : ''
                  )
                )
                this.$router.push({ path: '/home' })
                if (r.status && r.status !== 200) {
                  return false
                }
              })
              return false
            }
            if (res.data.data.saas && res.data.data.saas.length == 1) {
              localStorage.setItem(
                'saas',
                JSON.stringify(
                  res.data.data.saas.length ? res.data.data.saas[0] : ''
                )
              ) // 当前选择的系统的所有信息
              localStorage.setItem('company_id', res?.data?.data?.saas[0].id)
              userUseCompany({ company_id: res?.data?.data?.saas[0].id }).then((r) => {
                localStorage.setItem('saasTargetArchi',JSON.stringify(r?.data?.data?.department_info))
                this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                  if (r.data && r.data.code !== 200) {
                    return false
                  }
                  this.$router.push({ path: '/home' })
                }
              )
            } else {
              this.handleChangeMode('choseArchi', res.data.data)
            }
          }
          }
        }
      })
    },
    handleChangeMode(type, data = null) {
      this.$emit('changeMode', type, data)
    },
    openagreement(type) {
      const h = this.$createElement
      MessageBox({
        title: type === 'privacyagreement' ? '隐私协议' : '用户协议',
        message: type === 'privacyagreement' ? h(FuwuAgreement, null, []) : h(UserAgreement, null, []),
        showCancelButton: false,
        confirmButtonText: '确定',
        customClass: 'agreement-messagebox'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  .code-area{
    text-align: center;
    @imgSize: 180px;
    display: flex;
    padding: 10px 0px 8px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #FFF;
    .el-image{
      width: @imgSize;
      height: @imgSize;
    }
    .open {
      color: #424751;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.4px;
    }
    .image-slot{
      width: @imgSize;
      height: @imgSize;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .other-code-tips{
    text-align: center;
    margin-top: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135.5%;
    color: #AFAFAF;
  }
  .other-code-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    .item{
      & + .item {
        margin-left: 52px;
      }
    }
  }
  .agreement-tips-for-code{
    text-align: left;
    margin-top: 16px;
    .ordinary-text{
      color: #707786;
      text-align: justify;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.4px;
    }
    .active-text{
      color: #2358B4;
      cursor: pointer;
    }
  }
  .switch-versions {
    margin-top: 130px;
    span {
      color: #3364B8;
    }
  }
  .register {
    .active-text {
      color: #3364B8;
    }
  }
}
</style>

