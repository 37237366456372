<template>
  <div class="login-main-warp">
    <section class="left-sider">
      <!-- <div class="version" :style="versionBg"  @click="onchange">您正在浏览筑云飞椽云 {{version}}版本。可切换至 <span>{{ changeVersion }} 版本</span> </div> -->
      <div class="login-com-container">
        <div class="login-method-tabs" :style="code?'margin-bottom: 26px;':'margin-bottom: 32px;'">
          <div v-for="item in tabsList" :key="item.key" @click.stop="handleTbaItemClick(item)" :class="active === item.key ? 'active' : ''" class="tabs-item">
            <i class="iconfont icon" :class="item.icon" />
            <span class="text">{{ item.name }}</span>
          </div>
        </div>
        <section class="login-main">
          <!-- 登录的表单 -->
          <transition name="fade" mode="out-in">
            <LoginByCode v-if="code" :activeTab="active" key="code" :loginLoading="loginLoading" @changeMode="handleChangeMode" />
            <LoginByInput v-else-if="message" :activeTab="active" key="message" :loginLoading="loginLoading" @on-success-valid="handleSubmit" @changeMode="handleChangeMode" />
            <LoginByInput
              v-else-if="password"
              :activeTab="active"
              key="password"
              :loginLoading="loginLoading"
              @on-success-valid="handleSubmit"
              @changeMode="handleChangeMode"
              @changeActive="(res) => { active = res }"
            />
          </transition>
        </section>
      </div>
    </section>
    <Welcome />
  </div>
</template>
<script>
import LoginByCode from '@/manage-views/views/login/secondEdition/components/LoginByCode.vue'
import LoginByInput from '@/manage-views/views/login/secondEdition/components/LoginByInput.vue'
import Welcome from '@/manage-views/views/login/newLogin/components/Welcome.vue'
import { setToken } from '@/utils/tools'
import { userUseCompany } from '@/api/userInfo'
import axiosRequest from '@/api/mapi'
import { mapState } from 'vuex';
export default {
  components: { LoginByCode, LoginByInput, Welcome },
  data() {
    return {
      active: 'password',
      tabsList: [
        { name: '密码登录', key: 'password', icon: 'iconsuoding' },
        { name: '短信登录', key: 'message', icon: 'iconduanxindenglu' },
        { name: '扫码登录', key: 'code', icon: 'iconsaomadenglu' },
      ],
      loginLoading: false
    }
  },
  computed: {
    ...mapState(['loginVersion']),
    changeVersion() {
      if (this.loginVersion === 'V2.0') {
        return 'V1.0';
      } else {
        return 'V2.0';
      }
    },
    version(){
      console.log(this.loginVersion);
      return this.loginVersion
    },
    versionBg(){
      if (this.loginVersion === 'V2.0') {
        return "background: linear-gradient(270deg, rgba(150, 112, 255, 0.00) 12%, rgba(161, 180, 255, 0.60) 24.92%, #8CB7FF 34.51%, #70C3FE 45%, #58CFFC 55%, rgba(57, 217, 237, 0.80) 65%, rgba(92, 249, 255, 0.65) 76.06%, rgba(25, 248, 236, 0.00) 85%);";
      } else {
        return "background: linear-gradient(270deg, rgba(42, 152, 250, 0.00) 10%, rgba(42, 152, 250, 0.75) 25%, #36D1EC 45%, rgba(63, 224, 171, 0.70) 75%, rgba(63, 224, 171, 0.00) 85%);";
      }
    },
    password() {
      return this.active === 'password'
    },
    message() {
      return this.active === 'message'
    },
    code() {
      return this.active === 'code'
    }
  },
  mounted() {
    if (location.href.includes('index=1')) {
      this.active = 'message'
    }
  },
  methods:{
            /**
     * @desc: 切换版本
     */
     onchange() {
      if (this.loginVersion === 'V2.0') {
        this.$store.commit('setLoginVersion','V1.0') ;
      } else {
        this.$store.commit('setLoginVersion','V2.0') ;
      }
    },
    handleTbaItemClick(item) {
      if(!this.loading) {
        this.active = item.key
      }
    },
    handleSubmit({ userName, password, code }, version){
      this.loginLoading = true
      let data = {
        __method_name__: 'globalFunctionCall',
        funcName: 'LoginBehavior',
        type: 'behavior',
        payload: { name: userName, password: password, loginType: 'saas' },
        typeName: 'LoginFuncClass',
      }
      // 验证码登录
      if (this.active === 'message') {
        data.type = 'behavior'
        data.payload = {
          phone: userName,
          code: code,
          loginType: 'saasPhone',
        }
      }
      let pageInfo =JSON.parse(localStorage.getItem('pageInfo'))
      setToken('')
      sessionStorage.clear()
      localStorage.clear()
      if(pageInfo && pageInfo.length){
        localStorage.setItem('pageInfo',JSON.stringify(pageInfo))
      }
      axiosRequest.getDatalist(data, version === 'V2.0' ? 'https://dwh_api.bimcc.net/api/mapi' : '').then((res) => {
        if (res.data.code === 200) {
          // 当老用户没saas企业时 则不让他登录
          if(res.data.data.saas.length === 0 && res.data.data.saas_admin !== 1){
            this.$message.info('登录失败！该用户没有企业系统')
            this.loginLoading = false
            return
          }
          // 系统样式  默认为1 留下后面风格修改
          localStorage.setItem('system_style', 1)
          this.$message.success('登录成功！')
          setToken(res?.data?.data?.token)
          if (version === 'V2.0') {
            window.location.href = `https://newsaas.bimcc.com/login?token=${res?.data?.data?.token}`;
            return;
          }
          localStorage.setItem('is_company', res?.data?.data?.is_company)
          this.$store.commit('setUserInfo',res.data.data)
          const phoneReg = /^(\d{3})\d{6}(\d{2})$/
          let phone = res.data.data.phone
          if (this.userPhone) {
            this.userPhone = phone.replace(phoneReg, '$1******$2')
          }
          this.userName = res.data.data.name
          this.companyList = res.data.data.saas
          if (res.data.data.saas_admin == 1) {
            localStorage.setItem('company_id', 0) // 当前选择的系统的所有信息
            userUseCompany({ company_id: 0 }).then((r) => {
              localStorage.setItem(
                'saasTargetArchi',
                JSON.stringify(
                  r?.data?.data?.department_info
                )
              )
              this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
              localStorage.setItem(
                'saas',
                JSON.stringify(
                  res.data.data.saas.length ? res.data.data.saas[0] : ''
                )
              )
              this.$router.push({ path: '/home' })
              if (r.status && r.status !== 200) {
                return false
              }
            })
            return false
          }
          if (res.data.data.saas && res.data.data.saas.length == 1) {
            localStorage.setItem(
              'saas',
              JSON.stringify(
                res.data.data.saas.length ? res.data.data.saas[0] : ''
              )
            ) // 当前选择的系统的所有信息
            localStorage.setItem('company_id', res?.data?.data?.saas[0].id)
            userUseCompany({ company_id: res?.data?.data?.saas[0].id }).then((r) => {
              localStorage.setItem('saasTargetArchi',JSON.stringify(r?.data?.data?.department_info))
              this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                if (r.data && r.data.code !== 200) {
                  return false
                }
                this.$router.push({ path: '/home' })
              }
            )
          } else {
            this.handleChangeMode('choseArchi', res.data.data)
          }
        } else {
          this.$message.info(res.data.msg)
        }
        this.loginLoading = false
      })
      .catch(() => {
        this.$message.info('登陆失败！')
      }).finally(() => {
        this.loginLoading = false
      })
    },
    handleChangeMode(type, data = null) {
      this.$emit('changeMode', type, data)
    },
  }
}
</script>
<style lang="less">
  .agreement-messagebox{
    width: 800px;
    .el-message-box__message{
      max-height: 80vh;
      overflow: auto;
    }
  }
</style>
<style lang="less" scoped>
  .login-main-warp{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    .left-sider{
      height: 100%;
      @media (min-width: 1720px) {
        flex: 1;
      }
      @media (max-width: 1720px) {
        width: 680px;
      }
      .login-com-container{
        width: 400px;
        height: 100%;
        @media (min-width: 1720px) {
          margin: 12.76% 34.88% 0 15.34%;
        }
        @media (max-width: 1720px) {
          // padding: 120px 132px 0 132px;
          margin: 66px 254px 104px 160px;
        }
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .login-method-tabs{
          display: flex;
          align-items: center;
          box-sizing: border-box;
          .tabs-item{
            position: relative;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            box-sizing: border-box;
            filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
            color: #707786;
            transition: all 0.2s linear;
            cursor: pointer;
            &::after{
              transition: all 0.2s linear;
              transform: translateX(-50%) scaleX(0);
              content: '';
              width: 100%;
              position: absolute;
              left: 50%;
              bottom: 0;
              border-bottom: 3px solid #387FFC;
              color: #4086FF;
            }
            &.active{
              color: #181B22;
              &::after{
                content: '';
                width: 100%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%) scaleX(1);
                bottom: 0;
                border-bottom: 3px solid #387FFC;
              }
            }
            .icon{
              font-size: 16px;
            }
            .text{
              margin-left: 12px;
              font-family: "PingFang SC";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 26px; /* 144.444% */
              letter-spacing: 0.18px;
            }
            & + .tabs-item{
              margin-left: 18px;
            }
          }
        }
        .login-main{
          flex: 1;
        }
      }
      .version{
        display: flex;
        width: 680px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-4, 8px);
        height: 26px;
        border-radius: 360px;
        color: #fff;
        background-size: 100%;
        opacity: 0.85;
        color: var(--text-on-brand-primary, #FFF);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 185.714% */
        letter-spacing: 0.5px;
        span{
          cursor: pointer;
        }
      }
    }
    .fade-enter-active, .fade-leave-active {
      transition: all .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      transform: scale(0.95);
      opacity: 0;
    }
  }
</style>
