<template>
  <el-form
    ref="loginForm"
    :model="form"
    :rules="rules"
    @keydown.enter="handleSubmit"
    class="form-login"
    v-if="refresh"
  >
    <template v-if="password || message">
      <el-form-item prop="userName" class="form-item-bottom">
        <!--密码登录-->
        <div v-if="password" class="password-login">
          <el-input
            size="large"
            v-if="password"
            v-model="form.userName"
            placeholder="手机号、昵称或邮箱"
          ></el-input>
        </div>

        <!--短信登录-->
        <div v-if="message" class="sms-login message-login password-login">
          <el-input
            size="large"
            v-if="message"
            v-model="form.userName"
            placeholder="手机号"
          >
            <template #prefix>
              <span class="global-roaming">+86</span><el-icon class="el-input__icon"><search /></el-icon>
            </template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <!--密码登录-->
        <el-input
          size="large"
          v-if="password"
          type="password"
          show-password
          v-model="form.password"
          placeholder="密码"
        ></el-input>

        <!--短信登录-->
        <el-input
          size="large"
          v-if="message"
          v-model="form.password"
          placeholder="输入短信验证码"
        >
          <template #suffix>
            <span v-if="message" class="slot-icon">
              <a
                href="javascript:void(0)"
                @click="getCode"
                :style="{ color: time <= 0 ? '' : '#ccc' }"
              >
                获取短信验证码
                <span v-if="time">({{ time }}s)</span>
              </a>
            </span>
          </template>
        </el-input>
      </el-form-item>
      <div class="password-tips" :style="message?'padding: 12px 0 22px 0;':'padding: -24px 0 22px 0;'" v-if="password">
        <div class="remember-password">
          <!-- <el-checkbox v-model="isRememberpassword"></el-checkbox>
          <span class="text">记住密码</span> -->
        </div>
        <div
          class="forget-password"
          @click.stop="dochangeMode('forgetPasswordForm')"
        >
          忘记密码
        </div>
      </div>
      <el-form-item>
        <el-button
          :loading="loginLoading"
          class="submit"
          :style="message?'margin: 70px 0 12px 0;':'margin-bottom: 12px;'"
          size="large"
          @click="handleSubmit"
          type="primary"
          long
          >{{ message ? "登录 / 注册" : "登录" }}
        </el-button>
      </el-form-item>
      <div class="agreement-tips" :style="message?'margin-bottom: 130px;':'margin-bottom: 152px;'">
        {{ message?'未注册用户手机验证后自动登录, 注册即代表同意平台':'登录即代表同意平台' }}
        <span
          class="active-text emphasize"
          @click.stop="openagreement('privacyagreement')"
        >
          《隐私协议》
        </span>
        
        <span class="active-text emphasize" @click.stop="openagreement('userAgreement')">
          《用户协议》
        </span>
      </div>
      <div class="register-tips" :style="password?'justify-content: space-between;':'justify-content: center;'">
        <!-- <div class="switch-versions">筑云飞椽云{{ version }}  <span @click="onchange">切换{{ changeVersion }}</span></div> -->
        <div class="register" v-if="password">
          <span class="no-account">没有账号？</span>
          <span class="active-text" @click.stop="dochangeActive('message')">
            免费注册
          </span>
        </div>
      </div>
    </template>
  </el-form>
</template>

<script>
import { getLoginCode } from "@/api/user";
import FuwuAgreement from "@/website-views/views/applyDemo/components/FuwuAgreement.vue";
import UserAgreement from "@/website-views/views/applyDemo/components/UserAgreement.vue";
import { MessageBox,Icon } from "element-ui";
import { mapState } from 'vuex';
export default {
  components: {'el-icon':Icon },
  props: {
    userNameRules: {
      type: Object,
      default: () => {
        return {
          password: {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
          message: {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
        };
      },
    },
    passwordRules: {
      type: Object,
      default: () => {
        return {
          password: {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          message: { required: true, message: "请输入验证码", trigger: "blur" },
        };
      },
    },
    activeTab: {
      type: String,
      default: "",
    },
    loginLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        userName: "",
        password: "",
      },
      time: 0,
      timer: "",
      hasCode: false,
      //切换刷新
      refresh: true,
      rules: {
        userName: this.userNameRules[this.activeTab],
        password: this.passwordRules[this.activeTab],
      },
      isConsentAgreement: false,
      isRememberpassword: false,
    };
  },
  watch: {
    activeTab: {
      handler() {
        this.refresh = false;
        this.$nextTick(() => {
          if (location.href.split("phone=").length > 1) {
            this.form = {
              userName: location.href.split("phone=")[1],
              password: "",
            };
          } else {
            let recordForm = JSON.parse(localStorage.getItem("recordForm"));
            if (/^1[3456789]\d{9}$/.test(recordForm)) {
              this.form.userName = recordForm;
            } else {
              this.form.userName = "";
              this.form.password = "";
            }
          }
          this.refresh = true;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(['loginVersion']),
    password() {
      localStorage.setItem("recordForm", JSON.stringify(this.form.userName));
      return this.activeTab === "password";
    },
    message() {
      return this.activeTab === "message";
    },
  },
  mounted() {
    if (this.$route.query.phone) {
      this.form.phone = this.$route.query.phone;
    }
    try {
      const isConsentAgreement = localStorage.getItem("isConsentAgreement");
      this.isConsentAgreement = !!isConsentAgreement;
      let tempData = localStorage.getItem("TempData");
      if (tempData) {
        tempData = JSON.parse(tempData);
        if (tempData.userName && tempData.password) {
          this.isRememberpassword = true;
          this.form.userName = tempData.userName;
          this.form.password = tempData.password;
        }
      }
    } catch {
      // nothing to do
    }
  },
  created() {
    document.onkeydown = () => {
      let key = window.event.keyCode;
      if (key == 13) {
        this.handleSubmit();
      }
    };
  },
  methods: {
    dochangeMode(type, data = null) {
      this.$emit("changeMode", type, data);
    },
    dochangeActive(type) {
      this.$emit("changeActive", type);
    },
    handleSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.hasCode) {
            // 已发送验证码
            this.$emit("on-success-valid", {
              userName: this.form.userName,
              code: this.form.password,
            }, this.loginVersion);
          } else {
            // 未发送验证码
            localStorage.setItem(
              "TempData",
              JSON.stringify({
                userName: this.form.userName,
                password: this.form.password,
              }, this.loginVersion)
            );
            this.$emit("on-success-valid", {
              userName: this.form.userName,
              password: this.form.password,
              code: this.form.password,
            }, this.loginVersion);
          }
        }
      });
    },
    getCode() {
      if (this.time <= 0) {
        if (!this.form.userName) {
          this.$message.error("手机号不能为空");
          return;
        } else {
          if (!/^1[3456789]\d{9}$/.test(this.form.userName)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
        }
        getLoginCode({ prefix: "login_", phone: this.form.userName }).then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("发送成功！");
              this.hasCode = true;
              return;
            }
          }
        );
        this.time = 60;
        this.countDown();
      }
    },
    countDown() {
      if (this.time > 0) {
        this.timer = setInterval(() => {
          this.time = this.time - 1;
          if (this.time < 1) {
            clearInterval(this.timer);
            this.hasCode = false;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.hasCode = false;
      }
    },
    openagreement(type) {
      const h = this.$createElement;
      MessageBox({
        title: type === "privacyagreement" ? "隐私协议" : "用户协议",
        message:
          type === "privacyagreement"
            ? h(FuwuAgreement, null, [])
            : h(UserAgreement, null, []),
        showCancelButton: false,
        confirmButtonText: "确定",
        customClass: "agreement-messagebox",
      });
    },
  },
};
</script>
<style lang="less">
.form-login {
  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 10s !important;
  }
}
.agreement-tips-popper {
  background: #f0b213 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  transform: translateX(12px);
  .popper__arrow {
    border-top-color: #f0b213 !important;
    &::after {
      border-top-color: #f0b213 !important;
    }
  }
}
</style>
<style lang="less" scoped>
@inputHeight: 44px;
.sms-login {
  :deep(.el-input) {
    .el-input__inner {
      padding-left: 42px !important;
    }
  }
}
.form-login {
  .password-login {
    // margin-bottom: 24px;
  }
  .form-item-bottom {
    margin-bottom: 24px !important;
  }
  :deep(.el-form-item) {
    margin-top: 0;
    margin-bottom: 0;
    .message-login {
      .el-input__prefix, .el-input__suffix {
        top: 2px;
      }
    }
    .el-input {
      .el-input__inner {
        background: transparent !important;
        border-top: none;
        border-left: none;
        border-right: none;
        border-width: 1px;
        border-bottom-color: #E1E4EC;
        height: @inputHeight;
        line-height: @inputHeight;
        border-radius: 0px;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 0px;
        box-sizing: border-box;
        &::placeholder {
          color: #A4ACBD;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.14px;
        }
      }
    }
  }
  .global-roaming {
    color:#424751;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
  .password-tips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .remember-password {
      .text {
        margin-left: 16px;
        font-size: 14px;
        color: #2f2f2f;
      }
    }
    .forget-password {
      margin: 12px 0 36px 0;
      color: #707786;
      text-align: right;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      &:hover {
        cursor: pointer;
        color: #337effcc;
      }
    }
  }
  .agreement-tips {
    width: 400px;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-on-surface-tertiary, #707786);
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.4px;
    text-align: justify;
    .other-tips {
      text-align: left;
      color: var(--text-on-surface-tertiary, #707786);
      text-align: justify;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.4px;
      white-space: nowrap;
    }
    .item {
      transform: translate(2px, -4px);
    }
    .ordinary-check {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      :deep(.el-checkbox__input) {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        .el-checkbox__inner {
          width: 18px;
          height: 18px;
          border-radius: 4px;
        }
        .el-checkbox__inner::after {
          box-sizing: content-box;
          content: "";
          border: 1px solid #FFF;
          border-left: 0;
          border-top: 0;
          height: 7px;
          left: 6px;
          position: absolute;
          top: 3px;
          transform: rotate(45deg) scaleY(1);
          width: 3px;
          transition: transform .15s ease-in .05s;
          transform-origin: center;
        }
      }
    }
    .ordinary-text {
      color: #707786;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.4px;
      white-space: nowrap;
    }
  }
  .emphasize {
    color: #3364B8;
    cursor: pointer;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #387FFC;
    border-radius: 8px;
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
  .register-tips {
    display: flex;
    align-items: center;
    text-align: center;
    width: 400px;
    margin-top: 14px;
    color: #707786;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    .switch-versions {
      span {
        color: #3364B8;
        cursor: pointer;
      }
    }
    .register {
      .active-text {
        color: #3364B8;
      }
    }
  }
}
.slot-icon {
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  a {
    color: #387FFC;
  }
}
@media (max-width: 1366px) {
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
