<template>
  <el-form
    ref="loginForm"
    :model="form"
    :rules="rules"
    @keydown.enter="handleSubmit"
    class="form-login pass-form"
    autocomplete="off"
  >
    <!-- 假填充盒子 -->
    <div style="width:0;height:0;overflow:hidden;">
      <input type="text" name="prevent_autofill" id="prevent_autofill" value="" />
      <input type="password" name="password_fake" id="password_fake" value="" />
    </div>
    <header class="forget-form-header">
      忘记密码
    </header>
    <el-form-item prop="userName">
      <el-input
        size="large"
        v-model="form.userName"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input
        size="large"
        v-model="form.code"
        placeholder="请输入验证码"
      >
        <template #suffix>
          <span class="slot-icon">
            <a
              href="javascript:void(0)"
              @click="getCode"
              :style="{ color: time <= 0 ? '' : '#ccc' }"
            >
              获取验证码
              <span v-if="time">({{ time }}s)</span>
            </a>
          </span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        size="large"
        type="password"
        v-model="form.password"
        placeholder="请输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="repassword">
      <el-input
        size="large"
        type="password"
        v-model="form.repassword"
        placeholder="请再次输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        class="long-btn"
        size="large"
        @click="handleSubmit"
        type="primary"
        long
      >
        重置密码
      </el-button>
      <el-button
        class="long-btn"
        size="large"
        @click.stop="doBackLogin"
        type="text"
        long
      >
        返回登录
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { getChangePassCodeNew, reSetPassword } from "@/api/user";
import { setToken } from '@/utils/tools'
import { userUseCompany } from '@/api/userInfo'
import axiosRequest from '@/api/mapi'
export default {
  components: {},
  props:{
  },
  data(){
    return {
      form: {
        userName: "",
        code: "",
        password: "",
        repassword: "",
      },
      rules: {
        userName: [
          { validator: (rule, value, cb) => {
              if(!value) {
                cb(new Error('手机号不能为空！'))
              } else {
                const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
                if(!reg.test(value)) {
                  cb(new Error('手机号格式不正确'))
                } else {
                  cb()
                }
              }
            }, 
            trigger: 'blur' 
          }
        ],
        code: [
          { validator: (rule, value, cb) => {
              if(!value) {
                cb(new Error('验证不能为空！'))
              } else {
                const reg = /^\d{4}$/
                if(reg.test(value)) {
                  cb()
                } else {
                  cb(new Error('验证码格式不正确'))
                }
              }
            }, 
            trigger: 'blur' 
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        repassword: [
          { validator: (rule, value, cb) => {
              if(!value) {
                cb(new Error('确认密码不能为空！'))
              } else {
                if(this.form.password !== value) {
                  cb(new Error('两次密码不一致'))
                } else {
                  cb()
                }
              }
            }, 
            trigger: 'change' 
          }
        ],
      },
      hasCode: false,
      timer: "",
      time: 0,
    }
  },
  methods:{
    handleSubmit(){
      this.$refs.loginForm.validate((valid) => {
        if(valid) {
          reSetPassword({
            phone: this.form.userName,
            code: this.form.code,
            password: this.form.password,
            repassword: this.form.repassword
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success('提交成功！')
              this.handleLogin(this.form)
            }
        });
        }
      })
    },
    handleLogin({ userName, password }){
      this.loginLoading = true
      let data = {
        __method_name__: 'globalFunctionCall',
        funcName: 'LoginBehavior',
        type: 'behavior',
        payload: { name: userName, password: password, loginType: 'saas' },
        typeName: 'LoginFuncClass',
      }
      setToken('')
      sessionStorage.clear()
      localStorage.clear()
      axiosRequest.getDatalist(data).then((res) => {
        if (res.data.code === 200) {
          // 当老用户没saas企业时 则不让他登录
          if(res.data.data.saas.length === 0 && res.data.data.saas_admin !== 1){
            this.$message.info('登录失败！该用户没有企业系统')
            this.loginLoading = false
            return
          }
          // 系统样式  默认为1 留下后面风格修改
          localStorage.setItem('system_style', 1)
          this.$message.success('登录成功！')
          setToken(res?.data?.data?.token)
          localStorage.setItem('is_company', res?.data?.data?.is_company)
          this.$store.commit('setUserInfo',res.data.data)
          const phoneReg = /^(\d{3})\d{6}(\d{2})$/
          let phone = res.data.data.phone
          if (this.userPhone) {
            this.userPhone = phone.replace(phoneReg, '$1******$2')
          }
          this.userName = res.data.data.name
          this.companyList = res.data.data.saas
          if (res.data.data.saas_admin == 1) {
            localStorage.setItem('company_id', 0) // 当前选择的系统的所有信息
            userUseCompany({ company_id: 0 }).then((r) => {
              localStorage.setItem(
                'saasTargetArchi',
                JSON.stringify(
                  r?.data?.data?.department_info
                )
              )
              this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
              localStorage.setItem(
                'saas',
                JSON.stringify(
                  res.data.data.saas.length ? res.data.data.saas[0] : ''
                )
              )
              this.$router.push({ path: '/home' })
              if (r.status && r.status !== 200) {
                return false
              }
            })
            return false
          }
          if (res.data.data.saas && res.data.data.saas.length == 1) {
            localStorage.setItem(
              'saas',
              JSON.stringify(
                res.data.data.saas.length ? res.data.data.saas[0] : ''
              )
            ) // 当前选择的系统的所有信息
            localStorage.setItem('company_id', res?.data?.data?.saas[0].id)
            userUseCompany({ company_id: res?.data?.data?.saas[0].id }).then((r) => {
              localStorage.setItem('saasTargetArchi',JSON.stringify(r?.data?.data?.department_info))
              this.$store.commit('setSaasTargetArchi', r?.data?.data?.department_info)
                if (r.data && r.data.code !== 200) {
                  return false
                }
                this.$router.push({ path: '/home' })
              }
            )
          } else {
            this.handleChangeMode('choseArchi', res.data.data)
          }
        } else {
          this.$message.info(res.data.msg)
        }
        this.loginLoading = false
      })
      .catch(() => {
        this.$message.info('登陆失败！')
      }).finally(() => {
        this.loginLoading = false
      })
    },
    handleChangeMode(type, data = null) {
      this.$emit('changeMode', type, data)
    },
    getCode (){
      if (this.time <= 0) {
        if (!this.form.userName) {
          this.$message.error('手机号或邮箱不能为空')
          return;
        } else {
          if (!/^1[3456789]\d{9}$/.test(this.form.userName)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
        }
        getChangePassCodeNew({ phone:this.form.userName }).then((res) => {
          if (res.code == 200) {
            this.$message.success('发送成功！')
          }
        });
        this.time = 60;
        this.countDown();
      }
    },
    countDown(){
      if (this.time > 0) {
        this.timer = setInterval(() => {
          this.time = this.time - 1;
          if(this.time < 1){
            clearInterval(this.timer);
            this.hasCode = false;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.hasCode = false;
      }
    },
    doBackLogin() {
      this.$emit('changeMode', 'loginForm')
    }
  }
}
</script>
<style lang="less" scoped>
@inputHeight: 34px;
.form-login {
  width: 356px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(152, 160, 177, 0.25);
  border-radius: 4px;
  padding-bottom: 12px;
  :deep(.el-form-item) {
    margin-top: 12px;
    padding: 0 36px;
    box-sizing: border-box;
    .el-input{
      .el-input__inner{
        height: @inputHeight;
        line-height: @inputHeight;
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        &::placeholder{
          color: #9B9B9B;
        }
      }
    }
    .long-btn {
      width: 100%;
      height: @inputHeight;
      font-weight: 500;
      font-size: 14px;
      & + .long-btn{
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
  .forget-form-header{
    height: 58px;
    line-height: 58px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2F2F2F;
    margin-bottom: 40px;
  }
}
.slot-icon {
  color: #a1a5ad;
  font-size: 14px;
  border-left: 1px solid #dcdee2;
  padding-left: 10px;
  line-height: 40px;
  a {
    color: #0076ff;
  }
}
</style>