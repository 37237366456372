<template>
  <div class="login-main-warp">
    <section class="left-sider">
      <div class="login-com-container">
        <section class="login-main">
          <el-form
            ref="registerForm"
            class="form-chose-archi"
          >
            <header class="chose-archi-header">
              选择进入以下企业
            </header>
            <div class="chose-archi-tips">
              用技术让您复杂的工作更简单
            </div>
            <el-form-item v-for="item in componyList" :key="item.id">
              <el-button
                :loading="loading && checkData.id === item.id"
                class="long-btn"
                size="large"
                @click="doChoseItem(item)"
                type="primary"
                long
              >
                <i class="iconfont iconxuanze-jituan" />
                <span class="archi-name">{{ item.name }}</span>
              </el-button>
            </el-form-item>
            <div class="bind-archi-tips">
              <span class="active-text" @click.stop="handleChangeMode('loginForm')">
                返回
              </span>
            </div>
          </el-form>
        </section>
      </div>
    </section>
    <Welcome />
  </div>
</template>
<script>
import { userUseCompany } from '@/api/userInfo'
import Welcome from './Welcome.vue'
export default {
  components: { Welcome },
  props:{
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    componyList() {
      if(this.data && Array.isArray(this.data.saas) && this.data.saas.length) {
        return this.data.saas
      } else {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      checkData: {},
    }
  },
  methods:{
    handleChangeMode(type, data = null) {
      this.$emit('changeMode', type, data)
    },
    doChoseItem(data) {
      if(this.loading) {
        return
      }
      this.checkData = data
      this.loading = true
      userUseCompany({ company_id: data.id }).then((res) => {
        if (res.data && res.data.code !== 200) {
          return false
        }
        localStorage.setItem('saasTargetArchi',JSON.stringify(res?.data?.data?.department_info))
        this.$store.commit('setSaasTargetArchi', res?.data?.data?.department_info)
        localStorage.setItem('saas', JSON.stringify(data)) // 当前选择的系统的所有信息
        localStorage.setItem('company_id', res?.data?.data?.saas_company_id)
        this.$router.push({ path: '/home' })
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>
<style lang="less" scoped>
  .login-main-warp{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    .left-sider{
      height: 100%;
      @media (min-width: 1720px) {
        flex: 1;
      }
      @media (max-width: 1720px) {
        width: 680px;
      }
      .login-com-container{
        width: 100%;
        height: 100%;
        @media (min-width: 1720px) {
          padding: 12.76% 34.88% 0 15.34%;
        }
        @media (max-width: 1720px) {
          padding: 120px 132px 0 132px;
        }
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .login-main{
          flex: 1;
        }
      }
    }
    .right-sider{
      width: 640px;
      box-sizing: border-box;
      .icon{
        margin-top: 176px;
        width: 280px;
        height: 68px;
        background-image: url(../images/logo.png);
        background-size: 100% 100%;
      }
      .welcome{
        margin-top: 100px;
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 44px;
        color: #3F3F3F;
      }
      .welcome-text{
        margin-top: 32px;
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 44px;
        color: #3F3F3F;
      }
    }
  }
</style>
<style lang="less">
.agreement-tips-popper {
  background:#F0B213 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  .popper__arrow{
    border-top-color: #F0B213 !important;
    &::after{
      border-top-color: #F0B213 !important;
    }
  }
}
</style>
<style lang="less" scoped>
@inputHeight: 48px;
.form-chose-archi {
  :deep(.el-form-item) {
    margin-top: 12px;
  }
  .chose-archi-header{
    text-align: center;
    padding-bottom: 24px;
    font-size: 16px;
    color: #000000;
    box-sizing: border-box;
    border-bottom: 1px solid #BFBFBF;
  }
  .chose-archi-tips{
    margin-top: 36px;
    padding: 15px 12px;
    font-size: 12px;
    line-height: 18px;
    background: #E8F0FE;
    color: #3F4C5C;
  }
  .long-btn {
    margin-top: 14px;
    width: 100%;
    height: @inputHeight;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    padding: 0 26px;
    & + .long-btn{
      margin-top: 48px;
      margin-left: 0;
    }
    .archi-name{
      margin-left: 18px;
    }
  }
  .bind-archi-tips{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    .active-text{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #2358B4;
    }
  }
}
.slot-icon {
  color: #a1a5ad;
  font-size: 14px;
  border-left: 1px solid #dcdee2;
  padding-left: 10px;
  line-height: 40px;
  a {
    color: #0076ff;
  }
}
@media (max-width: 1366px) {
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>

